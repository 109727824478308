<template>
    <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/rotationManage"
          >广告区管理 / </router-link>
          <span class="crumbs_item crumbs_last">{{!operation?'新建':'编辑'}}</span></span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <span class="all_left_name all_required">主图</span>
        <div>
          <a-upload
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            list-type="picture-card"
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <img v-if="form.bannerLink" :src="form.bannerLink" style="max-width:180px" alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload> 
        </div>
      </div>

      <!-- 非首页app弹框 -->
      <div class="Up_Down_inner" v-show="code != 'AppHomePopup'">
        <div class="all_left_name all_required">标题</div>
        <div>
          <a-input
            v-model="form.bannerTitle"
            class="all_input all_margin-r"
            placeholder="请输入标题"
          />
        </div>
      </div>

      <!-- app首页弹框 -->
      <div class="Up_Down_inner" v-show="code == 'AppHomePopup'">
        <div class="all_left_name all_required">是否支持弹框关闭</div>
        <div>
          <a-select class="input all_margin-r" placeholder="选择是否支持弹框关闭" v-model="model_isClose">
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option value="0">
              不支持
            </a-select-option>
            <a-select-option value="1">
              支持
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="Up_Down_inner" v-show="code == 'AppHomePopup' && model_isClose == 1">
        <div class="all_left_name all_required">关闭计数方式</div>
        <div>
          <a-select class="input all_margin-r" placeholder="请选择关闭计数方式" v-model="model_numType">
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option value="1" v-if="model_isClose == 1">
              弹框关闭按钮
            </a-select-option>
            <a-select-option value="2">
              优惠券领取按钮
            </a-select-option>
            <a-select-option value="3">
              进入详情
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="Up_Down_inner" v-show="code == 'AppHomePopup' && model_isClose == 1">
        <div class="all_left_name all_required">显示次数</div>
        <div>
          <a-input-number
            :min="0"
            v-model="model_num"
            class="all_input_number all_margin-r"
            placeholder="请输入显示次数"
          />
        </div>
      </div>
      <!-- end-app首页弹框 -->
      
      <div class="Up_Down_inner" >
        <div class="all_left_name all_required">跳转方式</div>
        <div>
          <a-radio-group
            :options="plainOptions"
            v-model="form.jumpFlag"
          />
        </div>
      </div>
      <div class="Up_Down_inner" v-if="form.jumpFlag == 1">
        <div class="all_left_name all_required">跳转类型</div>
        <div>
          <a-select class="input all_margin-r" placeholder="选择跳转类型" v-model="form.jumpType" @change="jumpTypeChange">
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="item.value" v-for="item in jumpTypeOptions" :key="item.value">
              {{item.label}}
            </a-select-option>
          </a-select>
        </div>
      </div>

      <!--
        - 跳转链接 jumpFlag == 1
        - jumpType 1-外部网页链接 2-资讯文章详情   3-课程详情  6-优惠券弹框资讯列表   7-商品栏目列表
      -->
      <template v-if="form.jumpFlag == 1">
        <div class="Up_Down_inner" v-if="form.jumpType == 1">
          <div class="all_left_name all_required">网页链接</div>
          <div>
            <a-input
              v-model="form.jumpLink"
              class="all_input all_margin-r"
              placeholder="请输入需要跳转的链接"
            />
          </div>
        </div>

        <div class="Up_Down_inner" v-if="form.jumpType == 2">
          <div class="all_left_name all_required">资讯文章</div>
          <div>
            <a-select
              show-search
              optionFilterProp="label"
              class="input all_margin-r"
              v-model="form.jumpLink"
              placeholder="请选择需要跳转的资讯文章"
              @change="seleteItem"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option
                :value="item.id"
                v-for="(item, index) in newsList"
                :key="index"
                :label="item.articleTitle"
              >
                {{ item.articleTitle }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        
        <div class="Up_Down_inner" v-if="form.jumpType == 6">
          <div class="all_left_name all_required">优惠券弹框资讯文章</div>
          <div>
            <a-select
              show-search
              optionFilterProp="label"
              class="input all_margin-r"
              v-model="form.jumpLink"
              placeholder="请选择需要跳转的资讯文章"
              @change="seleteItem"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option
                :value="item.id"
                v-for="(item, index) in coupnPopupNewsList"
                :key="index"
                :label="item.articleTitle"
              >
                {{ item.articleTitle }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="Up_Down_inner" v-if="form.jumpType == 3">
          <div class="all_left_name all_required">课程</div>
          <div>
            <a-select
              show-search
              optionFilterProp="label"
              class="input all_margin-r"
              v-model="form.jumpLink"
              placeholder="请选择需要跳转的课程"
              @change="seleteItem"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option
                :value="item.courseId"
                v-for="(item, index) in courseList"
                :key="index"
                :label="item.courseName"
              >
                {{ item.courseName }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="Up_Down_inner" v-if="form.jumpType == 4">
          <div class="all_left_name all_required">直播详情</div>
          <div>
            <a-select
              show-search
              optionFilterProp="label"
              class="input all_margin-r"
              v-model="form.jumpLink"
              placeholder="请选择需要跳转的直播"
              @change="seleteItem"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option
                :value="item.id"
                v-for="(item, index) in liveInfoList"
                :key="index"
                :label="item.title"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="Up_Down_inner" v-if="form.jumpType == 7">
          <div class="all_left_name all_required">商品栏目</div>
          <div>
            <a-cascader
              class="cascader-input"
              :options="options"
              placeholder="请选择商品栏目"
              :fieldNames="{
                label: 'title',
                value: 'columnCode',
                children: 'subset'
              }"
              change-on-select
              v-model="jumpLink"
              @change="changejumpLink"
            />
          </div>
        </div>
      </template>

      <!-- 暂时废弃 -->
      <!-- <div class="Up_Down_inner" v-if="form.jumpFlag == 2">
        <span class="all_left_name all_required">图片/海报</span>
        <div>
          <a-upload
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            list-type="picture-card"
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :customRequest="customRequestLink"
          >
            <img v-if="form.jumpLink" :src="form.jumpLink" style="max-width:180px" alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </div>
      </div> -->
    </div>
    <div class="button">
      <a-button
        type="primary"
        class="btn"
        @click="addForm"
        :loading="loading"
      >确认</a-button>
      <a-button class="all_boder_btn btn" @click="goBack">取消</a-button>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {}, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      plainOptions: [
        { label: '暂不跳转', value: '0' },
        { label: '跳转链接', value: '1' },
        // { label: '跳转图片/海报', value: '2' }
      ],
      jumpTypeOptions: [
        { label: '外部网页链接', value: '1' },
        { label: '资讯文章详情', value: '2' },
        { label: '课程详情', value: '3' },
        { label: '直播详情', value: '4' },
        { label: '商品栏目列表', value: '7' },
        { label: '优惠券弹框资讯列表', value: '6' }
      ],
      courseList: [], // 课程列表
      newsList: [], // 资讯列表
      coupnPopupNewsList: [], // 优惠券弹框资讯列表
      liveInfoList: [], // 直播列表
      options:[], // 商品栏目树
      operation:'',
      loading:false,
      form: {},
      jumpLink: [], // 商品栏目回显

      code: null, // 位置code - AppHomePopup：app首页弹框
      model_isClose: undefined, // 是否支持弹框关闭 0-不支持 1-支持
      model_numType: undefined, // 计数方式 1-弹框关闭 2-优惠券领取 3-进入详情
      model_num: '', // 显示次数
    };
  }, // 事件处理器
  methods: {
    // 选择课程/文章
    seleteItem(e) {
      // console.log(e)
    },
    changejumpLink(e) {
      this.$set(this.form,'jumpLink',JSON.stringify(e))
    },
    // 跳转类型变化
    jumpTypeChange(e) {
      // 下拉框默认值
      if(e == 3 || e == 2 || e == 4) {
        this.$set(this.form,'jumpLink',undefined)
      }else if(e == 7) {
        this.$set(this,'jumpLink',[])
      }else{
        this.$set(this.form,'jumpLink','')
      }
    },

    // 获取课程列表
    getCourseList() {
      this.$ajax({
        url: "/hxclass-management/course/select",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.courseList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 资讯列表
    getData() {
      this.$ajax({
        url: "/hxclass-management/Article/list",
        method: "GET",
        params: {
          columnId: '',
          pageNum: 1,
          pageSize: 9999,
        },
      }).then((res) => {
        this.newsList = res.data.records;
      });
    },

    // 优惠券弹框资讯列表
    getcoupnPopupNewsList() {
      this.$ajax({
        url: "/hxclass-management/Article/client/list",
        method: "GET",
        params: {
          columnCode: 'CouponActivity',
          pageNum: 1,
          pageSize: 9999,
        },
      }).then((res) => {
        this.coupnPopupNewsList = res.data.records;
      });
    },

    // 直播列表
    getLiveInfoList() {
      this.$ajax({
        url: "/hxclass-management/liveInfo/getLiveInfoList",
        method: "GET",
        params: {
          pageNum: 1,
          pageSize: 9999,
        },
      }).then((res) => {
        this.liveInfoList = res.data.records;
      });
    },

    // 查询列表数据
    getShopList(){
      this.$ajax({
        url: '/hxclass-management/ProductColumn/tree',
        params:{
          title: this.title
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.options = res.data
        }
      }).catch(err=>{})
    },

    // 上传前钩子上传图片
    beforeUpload(file){
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg)
        return false
      }
    },

    // 主图文件上传
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'ueditor/banner/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.form.bannerLink = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 图片/海报文件上传
    customRequestLink(fileData) {
      this.loading = true;

      const bucket = 'ueditor/banner/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.form.jumpLink = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 增加数据
    addForm(){
      // 校验 
      if(!this.form.bannerLink){
        this.$message.warning('请上传主图图片');
        return
      }else if(!this.form.bannerTitle && this.code != 'AppHomePopup'){
        this.$message.warning('请填写标题');
        return
      }else if(this.model_isClose === undefined && this.code == 'AppHomePopup'){
        this.$message.warning('请选择是否支持弹框关闭');
        return
      }else if(!this.model_numType && this.code == 'AppHomePopup'){
        this.$message.warning('请选择关闭计数方式');
        return
      }else if(!this.model_num && this.code == 'AppHomePopup'){
        this.$message.warning('请填写显示次数');
        return
      }else if(this.form.jumpFlag === undefined){
        this.$message.warning('请选择跳转方式');
        return
      }else if(this.form.jumpFlag != '0' && !this.form.jumpLink){
        this.$message.warning(this.form.jumpFlag == 1?'请填写/选择跳转链接':'请上传图片/海报');
        return
      }

      // app首页弹框 - 拼接字段 ##分割
      if(this.code == 'AppHomePopup') {
        this.form.bannerTitle = [this.model_isClose,this.model_numType,this.model_num].join('##');
      }
      this.loading = true
      this.$ajax({
        method: 'post',
        url: '/hxclass-management/Banner/update',
        params: this.form
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$message.success('操作成功！');
          this.$router.go(-1);
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
    getFormDetai(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/Banner/detail/" + this.form.id,
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          res.data.jumpFlag = String(res.data.jumpFlag)
          if(res.data.jumpType == 2 || res.data.jumpType == 3 || res.data.jumpType == 6) {
            res.data.jumpLink = Number(res.data.jumpLink)
          }else if(res.data.jumpType == 7) {
            this.$set(this,'jumpLink',JSON.parse(res.data.jumpLink))
          }
          this.form = res.data
          if(this.type != 2) {  // 非精选，制空两个色值
            this.form.bgColorFirst = undefined
            this.form.bgColorSecond = undefined
          }

          // app首页弹框
          if(this.code == 'AppHomePopup' && res.data.bannerTitle) {
            this.model_isClose = res.data.bannerTitle.split('##')[0]; // 
            this.model_numType = res.data.bannerTitle.split('##')[1];
            this.model_num = res.data.bannerTitle.split('##')[2];
          }
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
    goBack(){
      this.$router.go("-1");
    }
  }, // 生命周期-实例创建完成后调用
  created() {
    this.form.locationId = this.$route.query.type;
    this.code = this.$route.query.code;

    // if(this.code == 'AppHomePopup') {
    //   let arr = [];
    //   this.jumpTypeOptions.map(item=>{
    //     // 首页弹框跳转类型只要优惠券和直播 新增课程详情
    //     if(item.value == 4 || item.value == 6 || item.value == 3) {
    //       arr.push(item)
    //     }
    //   })
    //   this.$set(this,'jumpTypeOptions',arr);
    // }
    
    if(this.$route.query.edit){
      this.form.id = this.$route.query.edit;
      this.getFormDetai()
    }
    this.operation = this.$route.query.edit;

    this.getCourseList(); // 获取课程列表
    this.getData(); // 获取资讯列表
    this.getShopList(); // 获取商品栏目列表
    this.getcoupnPopupNewsList(); // 优惠券弹框资讯列表
    this.getLiveInfoList(); // 直播列表
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {
    model_isClose(newVal,oldVal) {
      if(newVal == 0) {
        this.model_numType = undefined;
        this.model_num = '';
      }
    }
  },
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }
  .greytext {
       font-size: 14px;
       color: #888;
  }
  .all_content_box {
    .Up_Down_inner {
      display: block;
      margin-top: 24px;
      textarea.ant-input,
      .all_input {
        width: 525px;
      }
      .all_input_number{
        width: 397px;
      }
      textarea.ant-input {
        height: 192px;
      }
      .ant-calendar-picker,
      .input {
        width: 397px;
      }
      .short{
        width: 197px;
      }
    }
  }

  .button {
    .btn {
      margin: 36px 14px;
    }
  }
}
/deep/ .cascader-input{
  width: 200px;
}
</style>
